import classNames from 'classnames';
import PlaceholderImage from '../../PlaceholderImage';

type ImageProps = {
  url?: string | null;
  size?: 'fit' | '40' | '80' | '360';
  objectFit?: 'cover' | 'contain';
  fluid?: boolean;
  borders?: boolean;
  onImageLoad?: () => void;
  onImageError?: () => void;
  className?: string;
};

const Placeholder = () => (
  <PlaceholderImage className='m-auto block h-12 w-12 fill-current text-gray-300' />
);

/**
 * If url is empty, null or undefined, a placeholder will be displayed
 */
export const Image = ({
  url,
  size = '80',
  fluid,
  objectFit = 'cover',
  borders = true,
  onImageLoad,
  onImageError,
  className,
}: ImageProps) => {
  const img = url && (
    <img
      className={classNames(
        'block h-full w-full',
        objectFit === 'cover' ? 'object-cover' : 'object-contain'
      )}
      src={url}
      alt=''
      onLoad={onImageLoad}
      onError={onImageError}
    />
  );

  return (
    <div
      className={classNames(
        className,
        size === '40'
          ? 'h-10 w-10'
          : size === '80'
          ? 'h-20 w-20'
          : size === 'fit'
          ? 'h-full w-full '
          : 'max-h-80 w-full',
        !borders ? 'border-0' : 'border border-gray-200',
        `flex items-center overflow-hidden rounded bg-white md:rounded-md`
      )}
      style={fluid ? { width: '100%', height: '100%' } : undefined}
    >
      {img || <Placeholder />}
    </div>
  );
};
