import { Fragment, ReactNode, useState } from 'react';
import { JobStatus } from '~/generated/graphql';
import { graphql } from '~/gql';
import { groupTasks } from '~/helpers/groupCategories';
import Info from '~/icons/Info';
import { Job } from '..';
import { TaskListItem, ToggleTaskStatusFn } from './TaskListItem';

type TaskListProps = {
  job: Job;
  onToggle: ToggleTaskStatusFn;
  label?: ReactNode | string;
  taskToggleLoading?: string;
};

const TaskData_TaskFragment = graphql(`
  fragment TaskData on Task {
    id
    status
    type
    name
    description
    required
    tags {
      nodes {
        id
        entityType
        category
        name
        description
        colour
      }
    }
    attributes {
      id
      type
      category
      name
      value
    }
    jobAttributeIds
    notes
    assets {
      id
      status
      type
      name
      image
      attributes {
        name
        value
      }
      model {
        id
        brand
        name
      }
      deployment {
        id
        displayLocation
        startedAt
        image
        location {
          __typename
          id
          name
        }
        space {
          id
          name
        }
      }
    }
    config {
      selection
      spaces
      assetTypes
      allow
    }
    ...AttributeTask
    purchase {
      id
      spaces
      movements {
        id
        qty
      }
    }
    stocktake {
      id
      include
      itemStatus
      spaces
    }
    stockTransfer {
      id
      status
      sourceString
      destinationString
    }
  }
`);

export const TaskList = ({
  job,
  onToggle,
  label,
  taskToggleLoading = '',
}: TaskListProps) => {
  const tasks = job.tasks;
  const hasMandatoryTask = tasks.findIndex((task) => task.required) >= 0;
  const [showTasksInfo, setShowTasksInfo] = useState<boolean>(true);

  if (!tasks.length) {
    return null;
  }

  const grouped = groupTasks(job?.tasks);

  return (
    <>
      {label ?? (
        <p
          style={{
            marginTop: '1rem',
            marginBottom: '1rem',
            fontSize: '0.875rem',
            fontWeight: 700,
            color: '#00000096',
          }}
        >
          Tasks
        </p>
      )}
      {showTasksInfo &&
      hasMandatoryTask &&
      job.status === JobStatus.InProgress ? (
        <div className='mb-4 flex justify-start gap-2.5 rounded-lg bg-grey-10 text-sm text-secondary'>
          <Info width='44' height='44' />
          <p className='mb-2.5 mt-3.5 flex-1'>
            <span>
              Pre-listing Audit has mandatory tasks that are indicated by the
            </span>
            <div className='mx-1 inline-block'>
              <div className='flex h-4 w-4 items-center justify-center rounded-full bg-brand'>
                <span className='text-[9px] font-bold text-white'>M</span>
              </div>
            </div>
            <span>
              Badge. You will need to complete them in order to mark this Job as
              complete.
            </span>
          </p>
          <span
            className='x mt-2.5'
            onClick={() => setShowTasksInfo(false)}
          ></span>
        </div>
      ) : null}
      {Object.keys(grouped).map((key) => (
        <Fragment key={key}>
          {grouped[key][0]._name && (
            <p className='mb-1 px-1.5 pb-2 pt-4 text-sm font-semibold'>{key}</p>
          )}
          <ul className='mb-1'>
            {grouped[key].map((task, i) => (
              <TaskListItem
                key={task.id}
                job={job}
                task={task}
                onToggle={onToggle}
                isDisabled={
                  job.status === JobStatus.InProgress &&
                  job.enforceOrder &&
                  i > 0 &&
                  ![JobStatus.Complete, JobStatus.Incomplete].includes(
                    tasks[i - 1].status
                  )
                }
                taskToggleLoading={taskToggleLoading}
              />
            ))}
          </ul>
        </Fragment>
      ))}
    </>
  );
};
