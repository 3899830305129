import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { createElement, forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  countSideLayoutOpen,
  isFocusedAtom,
  useAtom,
  useSetAtom,
} from '~/atom';

type SideLayoutProps = {
  children: React.ReactNode;
  as?: React.ElementType<any>;
  /** @deprecated Use SideLayout.Head instead */
  header?: string;
  isPopup?: boolean;
  [x: string]: any;

  noScroll?: boolean;
  willOverflow?: boolean;
};

type AltProps = {
  children?: React.ReactNode;
};

type HeadProps = {
  children?: React.ReactNode;
  /** @deprecated avoid in favour of passing an action */
  leftSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
  secondRightSlot?: React.ReactNode;
  onClose?: CallableFunction;
  iconClass?: 'x' | 'chevron-left';

  /**
   * @deprecated
   */
  prevUrl?: string;
};

type BodyProps = React.HTMLAttributes<HTMLDivElement> & {
  children?: React.ReactNode;
  isPopup?: boolean;
};

type FootProps = {
  children: React.ReactNode;
  className?: string;
  show?: boolean;
  willOverflow?: boolean;
};

type Ref = HTMLDivElement;

function content(children: React.ReactNode) {
  return typeof children === 'string' ? <Body>{children}</Body> : children;
}

const Head = ({
  children,
  rightSlot,
  secondRightSlot,
  leftSlot,
  onClose,
  iconClass = 'x',
  prevUrl = '',
}: HeadProps) => {
  const renderLeft = () => {
    if (leftSlot) return leftSlot;
    if (prevUrl) return <Link to={prevUrl} className={'chevron-left'} />;
    if (onClose)
      return (
        <button
          type='button'
          className={classNames('hover:text-brand print:hidden', iconClass)}
          onClick={() => onClose()}
        ></button>
      );
  };

  return (
    <header className='flex items-start justify-between bg-white px-4'>
      <div className='h-8 w-8 pt-2.5 text-copy-nav'>{renderLeft()}</div>
      <div className='mx-1 mb-4 flex items-center justify-center overflow-visible break-words pt-4 text-center text-xl font-medium leading-none text-copy-head'>
        {children}
      </div>
      {secondRightSlot ? (
        <div className='flex items-center'>
          <div className='w-8 text-right text-sm text-copy-form'>
            {secondRightSlot}
          </div>

          <div className='w-8 text-right text-sm text-copy-form'>
            {rightSlot}
          </div>
        </div>
      ) : (
        <div className='w-8 text-right text-sm text-copy-form'>{rightSlot}</div>
      )}
    </header>
  );
};

const Body = forwardRef<Ref, BodyProps>(
  ({ children, isPopup, className, ...props }, ref) => (
    <div
      ref={ref}
      // Children can use this attribute to find the closest parent they should scroll
      data-autoscroll='1'
      className={classNames(
        className ?? 'px-4',
        isPopup ? 'lg:h-[651px]' : 'flex-grow'
      )}
      {...props}
    >
      {children}
    </div>
  )
);

const Foot = ({
  children,
  className,
  show = true,
  willOverflow,
}: FootProps) => {
  const [isFocused] = useAtom(isFocusedAtom);
  const hasChildren = React.Children.map(children, (child) => {
    if (child?.toString !== undefined) {
      return child.toString;
    }
  });
  return (
    <Transition
      as='footer'
      show={show && !isFocused}
      className={classNames(
        hasChildren?.length && 'sticky bottom-0 z-[65] border-t pb-6 lg:pb-4',
        'relative w-full bg-white',
        className,
        willOverflow && 'border-unset shadow-md'
      )}
      enter='transition-all duration-150 ease-in-out'
      enterFrom='-bottom-40 opactiy-0'
      enterTo='bottom-0 opacity-100'
      leave='transition-all duration-150 ease-in-out'
      leaveFrom='bottom-0 opacity-100'
      leaveTo='-bottom-40 opacity-0'
    >
      {children}
    </Transition>
  );
};

/**
 * Use `SideLayout` for the side panel common design elements
 */
export const SideLayout = ({
  as = 'div',
  children,
  header,
  isPopup,
  className,
  noScroll,
  willOverflow,
  ...props
}: SideLayoutProps) => {
  const setCount = useSetAtom(countSideLayoutOpen);
  useEffect(() => {
    setCount((n) => {
      if (n >= 0) document.documentElement.style.overflow = 'hidden';
      return n + 1;
    });
    return () => {
      setCount((n) => {
        if (n <= 1) document.documentElement.style.overflow = '';
        return n - 1;
      });
    };
  }, [setCount]);

  return createElement(
    as,
    {
      className: classNames(
        className,
        !isPopup && 'print:overflow-hidden h-screen lg:h-full',
        'bg-white z-20 flex-1 w-full lg:min-w-[414px] lg:border-grey-20 lg:border-l overflow-x-hidden flex flex-col',
        noScroll ? 'overflow-y-hidden' : 'overflow-y-auto',
        willOverflow && 'scrollbar-gutter-stable'
      ),
      ...props,
    },
    header && <div className=''>{header}</div>,
    content(children)
  );
};

export function SideLayoutAlt({ children }: AltProps) {
  return <div className='flex h-full flex-col overflow-hidden'>{children}</div>;
}

SideLayout.Head = Head;
SideLayout.Body = Body;
SideLayout.Foot = Foot;
